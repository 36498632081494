<template>
  <van-tabbar v-model="childActive" active-color="#f2dc97" :border="false" :style="{ background: bg }">
    <van-tabbar-item v-for="(item, index) in list" :key="item.icon" :name="item.name" :icon="item.icon" :to="item.to"
      :url="item.url" :badge="item.badge">{{ tabBar[index] }}</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { Token } from '@/utils/mm'
import userApi from '@/api/user'
export default {
  props: {
    active: {
      type: String,
      default: 'index',
    },
    bg: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters(['basicConfig']),
    tabBar() {
      let tabBar = [...this.$t('tabBar')]
      if (!this.basicConfig.IsOpenIncome) {
        tabBar.splice(1, 1)
        return tabBar
      } else {
        return tabBar
      }
    },
  },
  data() {
    return {
      childActive: this.active,
      list: [
        {
          name: 'index',
          icon: 'refund-o',
          to: 'Product',
        },
        {
          name: 'profit',
          icon: 'balance-pay',
          to: 'profit',
          //to: 'history-order',
        },
        // {
        //   name: 'service',
        //   icon: 'chat-o',
        //   to: '',
        // },
        {
          name: 'message',
          icon: 'chat-o',
          to: 'Message',
          badge: '',
        },
        {
          name: 'new',
          icon: 'fire-o',
          to: 'New',
        },
        {
          name: 'me',
          icon: 'manager-o',
          to: 'Me',
        },
      ]
    }
  },
  mounted() {
    if (!this.basicConfig.IsOpenIncome) {
      this.list.splice(1, 1)
    }
    if (Token.get()) {
      userApi.getUserMessageList({ page: 1, size: 100, isRead: false }).then((res) => {
        if (res.Data.length > 0)
          this.list.forEach(item => {
            if (item.name == 'message') {
              item.badge = res.Data.length
            }
          });
        else
          this.list.forEach(item => {
            if (item.name == 'message') {
              item.badge = ''
            }
          });
      })
    }
  },
}
</script>

<style lang="stylus" scoped>
.van-tabbar
  background: #201e1e;
  >>> .van-tabbar-item--active
    background: none;
</style>
