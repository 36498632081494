<template>
  <div class="header-container">
    <span class="goBack" @click="goBack">
      <van-icon name="wap-home-o" class="home" />
      <span>{{ $t('product').home }}</span>
    </span>
    <div class="right">
      <div class="userInfo">
        <p>{{ $t('index').Welcome }} {{ userInfo.NickName }}</p>
        <p class="amount" v-if="userInfo.Balance">
          {{ $t('product').Balance }} {{ basicConfig.Symbol
          }}{{ userInfo.Balance }}
        </p>
        <p class="amount" v-else @click="$router.push({ name: 'Login' })">
          {{ $t('index').PleaseLogin }}
        </p>
      </div>
      <!-- <div class="nav">
        <div @click="showLanguage = true">
          <van-icon name="info" size="15" />
          <span>{{ $t('index').lang }}</span>
        </div>
        <div @click="goRouter('With')">
          <van-icon name="gold-coin" size="15" />
          <span>{{ $t('product').Withdrawal }}</span>
        </div>
        <div @click="goRouter('Recharge')">
          <van-icon name="card" size="15" />
          <span>{{ $t('product').Inpour }}</span>
        </div>
      </div> -->
    </div>
    <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
  </div>
</template>

<script>
import Language from '@/components/language'
import { mapGetters } from 'vuex'
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    Language,
  },
  data() {
    return {
      showLanguage: false,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Home' })
    },
    goRouter(routerName) {
      this.$router.push({ name: routerName })
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.header-container
  position fixed
  top 0
  left 0
  right 0px
  height 51px
  display flex
  align-items center
  padding 0 7.5px
  color #fae39c
  background #1f1f1f
  z-index 99
  border-bottom: 1px solid #000;

  .home
    font-size 23px
    font-weight bold
    margin-bottom 5px
  .right
    display flex
    justify-content space-between
    align-items center
    width 100%
    .userInfo
      text-align left
      margin 5px 0 0  5px
      p
        font-size 12px
      .amount
        font-size 14px
        margin-top 10px
    .nav
      display flex
      justify-content flex-end
      font-size 14px
      div
        display flex
        justify-content flex-end
        align-items center
        height 50px
        margin 0 2px
        i
          width 14px
          height 14px
          margin-right 6px
  .goBack
    flex-column()
    width 50px
    align-items center
</style>
